/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/jquery-ui@1.12.1/ui/widget.min.js
 * - /npm/jquery.easing@1.4.1/jquery.easing.min.js
 * - /npm/jquery-color-animation@1.5.1/jquery.animate-colors-min.js
 * - /npm/jquery-textfill@0.6.0/source/jquery.textfill.min.js
 * - /npm/highcharts@9.3.2/highcharts.min.js
 * - /npm/highcharts@9.3.2/highcharts-more.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
